<template>
    <div>
        <el-upload
                :disabled="isDisabled"
                :action="actionUrl"
                list-type="picture-card"
                accept=".3GP,.ASF,.AVI,.DAT,.DV,.FLV,.F4V,.GIF,.M2T,.M4V,.MJ2,.MJPEG,.MKV,.MOV,.MP4
                        ,.MPE,.MPG,.MPEG,.MTS,.OGG,.QT,.RM,.RMVB,.SWF,.TS,.VOB,.WMV,.WEB"
                :on-success="handleSuccess"
                :file-list="fileLists"
                :on-change="handleChange"
                :before-upload="beforeAvatarUploadFbVideo"
                :on-progress="handProgress"
                :class="{hide:hideUploadEdit}">
            <i slot="default" class="el-icon-plus" ></i>
            <div slot="file" slot-scope="{file}">
                <video class="el-upload-list__item-thumbnail" :src="file.url" alt="" controls="controls" style="height: 170px;width: 170px"></video>
                <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove(file)" v-if="!isDisabled">
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
            </div>
        </el-upload>
        <el-progress :text-inside="true" color='#f56c6c' :stroke-width="10" :percentage="uploadProgress" v-show='preventSumit'></el-progress>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <video width="100%" :src="dialogImageUrl" alt="" controls="controls"></video>
        </el-dialog>
    </div>

</template>
<script>
    import ty from "../../utils/ty";
    export default {
        name: "VploadVideo",
        props:{
            limit:{
                type:Number,
                default:12,
            },
            action:{
                type:String,
                default:ty.apiurl+'/common/uploadFileUrl'
            },
            type:{
                type:Number,
                default:0
            },
            isDisabled:{
                type:Boolean,
                default:false
            },
            fileList:{
                type:Array,
                default: ()=>{
                    return []
                }
            }
        },

        data(){
            return{
                dialogImageUrl: '',
                dialogVisible: false,
                actionUrl: this.action+'?fileType=0&type='+this.type,
                hideUploadEdit:this.fileList.length>=this.limit,
                fileLists:this.fileList,
                editForm:{
                    url:'',
                    uid:null
                },
                editView:false,
                preventSumit:false,
                progressFactor:0.96,
                uploadProgress:0,
            }
        },
        watch:{
            fileList(n,o){ //n为新值,o为旧值;
                this.fileLists = n;
                this.hideUploadEdit=this.fileList.length>=this.limit
            }
        },
        methods:{
            // 移除图片
            handleRemove(file) {
                for(let i in this.fileLists){
                    if(this.fileLists[i].uid == file.uid){
                        this.fileLists.splice(i,1)
                    }
                }
                this.hideUploadEdit = this.fileLists.length>=1;
                this.submitFile(file.url,0)
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleChange(file,fileList){
                this.hideUploadEdit = fileList.length>=1;
            },
            handleSuccess(response,file,fileList){
                let obj = {
                    name: file.name,
                    status: "success",
                    uid: file.uid,
                    url: response.msg
                }
                this.uploadProgress = 100
                this.preventSumit = false

                this.fileLists.push(obj)
                this.submitFile(response.msg,1)
            },
            handProgress (file) {
                console.log('ssssssssssssss')
                this.uploadProgress = parseInt(file.percent*this.progressFactor)
            },
            //视频
            beforeAvatarUploadFbVideo(file) {
                var that = this;
                let url = URL.createObjectURL(file);
                var audioElement = new Audio(url);
                audioElement.addEventListener("loadedmetadata", function () {
                    that.playTime = audioElement.duration; //playTime就是当前视频长度
                    that.playTimevideo(that.playTime)
                });
                this.progressFactor = Math.random(1)
                this.preventSumit = true
            },
            // 将图片文件传回给父组件
            submitFile(filePath,add){
                //add:0是删除。1是新增
                this.$emit('submitImg',filePath,add)
            },
            playTimevideo(playTime){
                this.$emit('playTimevideo',playTime)
            }
        }
    }
</script>

<style scoped>
    .el-icon-plus{
        font-size: 30px!important;
    }

    .el-icon-zoom-in{
        font-size: 18px !important;
    }
    .el-icon-delete{
        font-size: 18px !important;
        color:rgb(243, 143, 130);
    }
    .el-input>>> .el-textarea__inner{
        font-size:18px!important;
    }
    .hide  >>> .el-upload--picture-card{
        display: none;
    }
</style>
